import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import A from "../images/greenTick2.jpg"
import B from "../images/ShippingBanner0.png"

import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"
import "../components/static/styles/mainPost.css"

const divBackgroundStyles = {
  backgroundColor: `#e5e5e8`,
  paddingTop: `1rem`,
  paddingBottom: `1rem`,
}

const divMainStyles = {
  backgroundColor: `#f6f6f6`,
  display: `grid`,
  gridTemplateRows: `20rem 30rem`,
  gridTemplateColumns: `1fr`,
  margin: `2rem`,
  padding: `2rem`,
}

const divFirstStyles = {
  display: `grid`,
  gridTemplateRows: `8rem 2rem 8.5rem`,
  gridTemplateColumns: `1fr`,
  alignItems: `center`,
  justifyItems: `center`,
}

const imgStyles = {
  maxWidth: `100%`,
  maxHeight: `6rem`,
  display: `grid`,
}

const paraStyles = {
  margin: `1rem 0`,
  textAlign: `center`,
}

const divSecondStyles = {
  display: `grid`,
  gridTemplateRows: `3.3rem 16rem 11rem`,
  gridTemplateColumns: `1fr`,
}

const orderDetailsStyles = {
  fontSize: `small`,
  borderBottom: `red 2px solid`,
  maxWidth: `fit-content`,
}

const lineStyles = {
  minWidth: `70%`,
  borderBottom: `1px solid grey`,
  position: `absolute`,
  paddingTop: `2.3rem`,
}

const orderRefDiv = {
  display: `grid`,
  gridTemplateRows: `repeat(5, 3rem)`,
  gridTemplateColumns: `1fr 1fr`,
  columnGap: `1rem`,
  marginTop: `0.7rem`,
  alignItems: `baseline`,
}

const orderRefTopicStyles = {
  fontSize: `small`,
  fontWeight: `bold`,
  textAlign: `end`,
}

const orderRefDetailStyles = {
  color: `grey`,
}

const ShopNow = {
  maxHeight: "100%",
  maxWidth: "100%",
  marginTop: "0.7rem",
  marginBottom: "1.0rem",
}

const gmtDate = () => {
  const str = new Date().toISOString()
  let res = str.split("T")
  const res_mod = res[0] + " GMT"
  return res_mod
}

function Post() {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="mainPost">
        <img src={sideImageL} alt="sidebanner" className="sideImageLPost" />
        <div style={divBackgroundStyles} className="divStyles__post">
          <div style={divMainStyles}>
            <div style={divFirstStyles}>
              <img src={A} style={imgStyles} alt="accepted" />
              <h3>Congratulations</h3>
              <p style={paraStyles}>
                Your product has been ordered and on its way to you soon.
                Meanwhile, for any questions, please contact{" "}
                <strong>email@email.com</strong>
              </p>
            </div>
            <div style={divSecondStyles}>
              <p className="OrderDetails" style={orderDetailsStyles}>
                <strong>ORDER DETAILS</strong>
              </p>
              <div style={lineStyles}> </div>
              <div style={orderRefDiv}>
                <p style={orderRefTopicStyles}>ORDER REF</p>
                <p style={orderRefDetailStyles}>986595</p>
                <p style={orderRefTopicStyles}>ORDER DATE</p>
                <p style={orderRefDetailStyles}>{gmtDate()}</p>
                <p style={orderRefTopicStyles}>PRODUCT ORDERED</p>
                <p style={orderRefDetailStyles}>Kvadrat Feld 685</p>
                <p style={orderRefTopicStyles}>PAYMENT TYPE</p>
                <p style={orderRefDetailStyles}>Credit Card</p>
                <p style={orderRefTopicStyles}>PAYMENT STATUS</p>
                <p style={orderRefDetailStyles}>Successful</p>
              </div>
              <div>
                <img src={B} alt="Shipping Times Banner" style={ShopNow} />
                <p
                  style={{
                    textAlign: "center",
                    margin: "1rem",
                    fontSize: "small",
                  }}
                >
                  Feel free to bookmark the <a href="/"> Home page</a> to save
                  details for future reference
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src={sideImageR} alt="sidebanner" className="sideImageRPost" />
      </div>
    </Layout>
  )
}

export default Post
